<template>
  <div>
    <div>
      <div
        ref="form"
        :style="{height: trHeight}"
        class="repeater-form"
      >
        <b-row
          v-for="(item) in items"
          :id="item.id"
          :key="item.id"
          ref="row"
          bg-variant="light"
        >
          <b-col md="12">
            <div class="d-flex border rounded flex-column mb-1 position-relative">
              <b-link
                class="position-absolute position-right-0 zindex-1 sidebar-repeater-delete"
                @click="removeItem(index)"
              >
                <feather-icon
                  icon="XIcon"
                  size="16"
                />
              </b-link>
              <div class="row flex-grow-1 px-2 py-1">
                <b-col
                  md="6"
                >
                  <b-form-group
                    :label="$t('shop.calendar.appointment.service')"
                    label-for="service"
                  >
                    <v-select
                      v-model="item.service"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="serviceOptions"
                      label="name"
                      :reduce="service => service.id"
                      input-id="service"
                    >
                      <template v-slot:option="option">
                        <div class="d-flex justify-content-between">
                          <span>{{ option.name }}</span>
                          <span class="text-muted mr-2">&euro; {{ option.price | formatNumber(2) }}</span>
                        </div>
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>
                <b-col
                  md="6"
                >
                  <b-form-group
                    :label="$t('shop.calendar.appointment.employees')"
                    label-for="employee"
                  >
                    <v-select
                      v-model="item.employees"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="employeeOptions"
                      label="title"
                      :reduce="employees => employees.id"
                      input-id="employees"
                      multiple
                    >

                      <template #option="{ color, title }">
                        <div
                          class="rounded-circle d-inline-block mr-50"
                          :class="`bg-${color}`"
                          style="height:10px;width:10px"
                        />
                        <span> {{ title }}</span>
                      </template>

                      <template #selected-option="{ color, title }">
                        <div
                          class="rounded-circle d-inline-block mr-50"
                          :class="`bg-${color}`"
                          style="height:10px;width:10px"
                        />
                        <span> {{ title }}</span>
                      </template>
                    </v-select>

                  </b-form-group>
                </b-col>
              </div>
            </div>
          </b-col>
        </b-row>

      </div>
    </div>
    <b-link
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="repeateAgain"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      <span>{{ $t('shop.calendar.appointment.add_service') }}</span>
    </b-link>
  </div>
</template>

<script>
import {
  BFormGroup, BRow, BCol, BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BLink,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    employeeOptions: {
      type: Array,
      required: true,
    },
    serviceOptions: {
      type: Array,
      required: true,
    },
    eventLocal: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      items: [{
        id: 1,
        selected: 'male',
        selected1: 'designer',
        prevHeight: 0,
      }],
      nextTodoId: 2,
    }
  },
  mounted() {
    // this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
      })

      // this.$nextTick(() => {
      //   this.trAddHeight(this.$refs.row[0].offsetHeight)
      // })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  transition: .35s height;
}
.sidebar-repeater-delete {
  padding: .3rem .4rem;
}
</style>
