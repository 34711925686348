import store from '@/store'

import { computed } from '@vue/composition-api'

export default function useCalendarSidebar() {
  const employeeOptions = computed(() => store.state.calendar.employeeOptions)
  const selectedEmployees = computed({
    get: () => store.state.calendar.selectedEmployees,
    set: val => {
      store.commit('calendar/SET_SELECTED_EMPLOYEES', val)
    },
  })

  const checkAllEmployees = computed({
    /*
      GET: Return boolean `true` => if length of options matches length of selected filters => Length matches when all events are selected
      SET: If value is `true` => then add all available options in selected filters => Select All
           Else if => all filters are selected (by checking length of both array) => Empty Selected array  => Deselect All
    */
    get: () => selectedEmployees.value.length === employeeOptions.value.length,
    set: val => {
      if (val) {
        selectedEmployees.value = employeeOptions.value.map(i => i.id)
      } else if (selectedEmployees.value.length === employeeOptions.value.length) {
        selectedEmployees.value = []
      }
    },
  })

  return {
    employeeOptions,
    selectedEmployees,
    checkAllEmployees,
  }
}
