<template>
  <div>
    <b-sidebar
      id="sidebar-add-new-event"
      sidebar-class="sidebar-xl"
      :visible="isEventHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ currentTab }} {{ $t('buttons.add') | lowercase }}
          </h5>
          <div>
            <feather-icon
              v-if="event.id"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="$emit('remove-event'); hide();"
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <b-tabs fill>
          <b-tab
            active
            :title="$t('shop.calendar.appointment.title')"
            class="p-2"
            @click="currentTab = $t('shop.calendar.appointment.title')"
          >
            <tab-appointment
              :is-event-handler-sidebar-active="isEventHandlerSidebarActive"
              :event="event"
              :clear-event-data="clearEventData"
            />
          </b-tab>

          <b-tab
            :title="$t('shop.calendar.absence')"
            class="p-2"
            @click="currentTab = $t('shop.calendar.absence')"
          >
            <tab-absence />
          </b-tab>

          <b-tab
            :title="$t('shop.calendar.task')"
            class="p-2"
            @click="currentTab = $t('shop.calendar.task')"
          >
            <tab-task />
          </b-tab>

          <b-tab
            :title="$t('shop.calendar.note')"
            class="p-2"
            @click="currentTab = $t('shop.calendar.note')"
          >
            <tab-note />
          </b-tab>
        </b-tabs>

      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BTabs, BTab,
} from 'bootstrap-vue'
import i18n from '@/libs/i18n'
import TabAppointment from './tabs/TabAppointment.vue'
import TabAbsence from './tabs/TabAbsence.vue'
import TabNote from './tabs/TabNote.vue'
import TabTask from './tabs/TabTask.vue'

export default {
  components: {
    TabAppointment,
    TabAbsence,
    TabNote,
    TabTask,
    BSidebar,
    BTabs,
    BTab,
  },
  model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      currentTab: i18n.t('shop.calendar.appointment.title'),
    }
  },
}
</script>

<style>

</style>
