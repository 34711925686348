<template>
  <!-- Body -->
  <validation-observer
    #default="{ handleSubmit }"
    ref="refFormObserver"
  >

    <!-- Form -->
    <b-form
      @submit.prevent="handleSubmit(onSubmit)"
      @reset.prevent="resetForm"
    >
      <appointment
        :employee-options="employeeOptions"
        :service-options="serviceOptions"
        :event-local="eventLocal"
        class="mb-2"
      />

      <b-row>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            :name="$t('shop.calendar.appointment.date')"
            rules="required"
          >

            <b-form-group
              :label="$t('shop.calendar.appointment.date')"
              label-for="start-date"
              :state="getValidationState(validationContext)"
            >
              <flat-pickr
                v-model="eventLocal.start"
                class="form-control"
                :config="{ enableTime: false, dateFormat: 'Y-m-d'}"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            :name="$t('shop.calendar.appointment.time')"
            rules="required"
          >

            <b-form-group
              :label="$t('shop.calendar.appointment.time')"
              label-for="start-time"
              :state="getValidationState(validationContext)"
            >
              <flat-pickr
                v-model="eventLocal.time"
                class="form-control"
                :config="{ enableTime: true, noCalendar: true, time_24hr: true, dateFormat: 'H:i'}"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      <!-- Customer -->
      <b-form-group
        :label="$t('shop.calendar.appointment.customer')"
        label-for="add-customers"
      >
        <v-select
          v-model="eventLocal.extendedProps.guests"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="name"
          input-id="add-customers"
          :options="customerOptions"
        >

          <template #option="{ firstName, lastName }">
            <b-avatar
              size="sm"
            />
            <span class="ml-50 align-middle">{{ firstName }} {{ lastName }}</span>
          </template>

          <template #selected-option="{ firstName, lastName }">
            <b-avatar
              size="sm"
              class="border border-white"
            />
            <span class="ml-50 align-middle">{{ firstName }} {{ lastName }}</span>
          </template>
        </v-select>
      </b-form-group>

      <!-- Textarea -->
      <b-form-group
        :label="$t('shop.calendar.appointment.note')"
        label-for="event-note"
      >
        <b-form-textarea
          id="event-note"
          v-model="eventLocal.extendedProps.description"
        />
      </b-form-group>

      <!-- Form Actions -->
      <div class="d-flex mt-2">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-2"
          type="submit"
        >
          {{ eventLocal.id ? 'Update' : 'Add ' }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="reset"
          variant="outline-secondary"
        >
          Reset
        </b-button>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BForm, BFormInvalidFeedback, BFormGroup, BFormTextarea, BAvatar, BButton, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, toRefs } from '@vue/composition-api'
import { required, email, url } from '@validations'
import useCalendarEventHandler from '@/views/shop/calendar/calendar-event-handler/useCalendarEventHandler'
import flatPickr from 'vue-flatpickr-component'
import Appointment from '../Appointment.vue'

export default {
  components: {
    BForm,
    BFormInvalidFeedback,
    BFormGroup,
    BFormTextarea,
    BAvatar,
    BButton,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    flatPickr,
    Appointment,
  },
  directives: {
    Ripple,
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      url,
    }
  },
  setup(props, { emit }) {
    /*
     ? This is handled quite differently in SFC due to deadlock of `useFormValidation` and this composition function.
     ? If we don't handle it the way it is being handled then either of two composition function used by this SFC get undefined as one of it's argument.
     * The Trick:

     * We created reactive property `clearFormData` and set to null so we can get `resetEventLocal` from `useCalendarEventHandler` composition function.
     * Once we get `resetEventLocal` function which is required by `useFormValidation` we will pass it to `useFormValidation` and in return we will get `clearForm` function which shall be original value of `clearFormData`.
     * Later we just assign `clearForm` to `clearFormData` and can resolve the deadlock. 😎

     ? Behind The Scene
     ? When we passed it to `useCalendarEventHandler` for first time it will be null but right after it we are getting correct value (which is `clearForm`) and assigning that correct value.
     ? As `clearFormData` is reactive it is being changed from `null` to corrent value and thanks to reactivity it is also update in `useCalendarEventHandler` composition function and it is getting correct value in second time and can work w/o any issues.
    */
    const clearFormData = ref(null)

    const {
      eventLocal,
      resetEventLocal,
      employeeOptions,
      serviceOptions,
      customerOptions,

      // UI
      onSubmit,
    } = useCalendarEventHandler(toRefs(props), clearFormData, emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetEventLocal, props.clearEventData)

    clearFormData.value = clearForm

    return {
      // Add New Event
      eventLocal,
      employeeOptions,
      serviceOptions,
      customerOptions,
      onSubmit,

      // Form Validation
      resetForm,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
