export default {
  namespaced: true,
  state: {
    tableFields: [
      { key: 'newsletter', label: '' },
      { key: 'name', label: 'Voor / achternaam', sortable: true },
      { key: 'address', sortable: true },
      { key: 'phoneNumber' },
      { key: 'actions' },
    ],
  },
  getters: {},
  mutations: {},
  actions: {
  },
}
