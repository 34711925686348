<template>
  <div class="app-calendar overflow-hidden border">
    <div class="row no-gutters">
      <!-- Sidebar -->
      <div
        class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
        :class="{'show': isCalendarOverlaySidebarActive}"
      >
        <calendar-sidebar :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive" />
      </div>

      <!-- Calendar -->
      <div class="col position-relative d-flex">
        <div class="card shadow-none border-0 mb-0 rounded-0 flex-grow-1">
          <div class="card-body pb-0">
            <div class="fc fc-direction-ltr">
              <div class="fc-header-toolbar fc-toolbar">
                <div class="fc-toolbar-chunk">
                  <div class="fc-button-group">
                    <button
                      type="button"
                      title="Previous day"
                      aria-pressed="false"
                      class="fc-prev-button fc-button fc-button-primary"
                      @click="calendarFunctions.prev()"
                    >
                      <span class="fc-icon fc-icon-chevron-left" />
                    </button>
                    <button
                      type="button"
                      title="Next day"
                      aria-pressed="false"
                      class="fc-next-button fc-button fc-button-primary"
                      @click="calendarFunctions.next()"
                    >
                      <span class="fc-icon fc-icon-chevron-right" />
                    </button>
                  </div>
                  <div class="flatpickr">
                    <flat-pickr
                      v-model="currentDate"
                      class="form-control"
                      name="date"
                      format="d-m-Y"
                      :config="{ altInput: true, altFormat: 'j F Y', dateFormat: 'Y-m-d', wrap: true, position: 'auto center' }"
                      data-input
                      @input="calendarFunctions.gotoDate(currentDate)"
                    />
                  </div>
                </div>

                <div class="fc-toolbar-chunk" />
                <div class="fc-toolbar-chunk">
                  <div class="fc-button-group">
                    <button
                      type="button"
                      title="month view"
                      aria-pressed="false"
                      class="fc-dayGridMonth-button fc-button fc-button-primary"
                      @click="calendarFunctions.changeView('dayGridMonth')"
                    >
                      month
                    </button>
                    <button
                      type="button"
                      title="week view"
                      aria-pressed="false"
                      class="fc-timeGridWeek-button fc-button fc-button-primary"
                      @click="calendarFunctions.changeView('timeGridWeek')"
                    >
                      week
                    </button>
                    <button
                      type="button"
                      title="day view"
                      aria-pressed="false"
                      class="fc-timeGridDay-button fc-button fc-button-primary"
                      @click="calendarFunctions.changeView('resourceTimeGridDay')"
                    >
                      day
                    </button>
                    <button
                      type="button"
                      title="list view"
                      aria-pressed="false"
                      class="fc-listMonth-button fc-button fc-button-primary"
                      @click="calendarFunctions.changeView('listWeek')"
                    >
                      list
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <full-calendar
              ref="refCalendar"
              :options="calendarOptions"
              class="full-calendar"
            >
              <template v-slot:eventContent="arg">
                <div class="fc-event-time">
                  {{ arg.timeText }}
                </div>
                <div class="fc-event-title">
                  {{ arg.event.extendedProps.customer }}
                </div>
                <div class="fc-event-description">
                  {{ arg.event.title }}
                </div>
              </template>

              <template v-slot:resourceLabelContent="arg">

                <b-avatar
                  :text="avatarText(`${ arg.resource.title }`)"
                  size="26px"
                  rounded-circle
                  :variant="arg.resource.extendedProps.color"
                />
                {{ arg.resource.title }}
              </template>
            </full-calendar>
          </div>
        </div>
      </div>

      <!-- Sidebar Overlay -->
      <div
        class="body-content-overlay"
        :class="{'show': isCalendarOverlaySidebarActive}"
        @click="isCalendarOverlaySidebarActive = false"
      />
      <calendar-event-handler
        v-model="isEventHandlerSidebarActive"
        :event="event"
        :clear-event-data="clearEventData"
      />
    </div>
  </div>
</template>

<script>
import {
  BAvatar,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import FullCalendar from '@fullcalendar/vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import calendarStoreModule from './calendarStoreModule'
import customerStoreModule from '../customers/customerStoreModule'
import CalendarSidebar from './calendar-sidebar/CalendarSidebar.vue'
import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'
import useCalendar from './useCalendar'

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    CalendarSidebar,
    CalendarEventHandler,
    flatPickr,
    BAvatar,
  },
  setup() {
    const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'
    const USER_CUSTOMER_STORE_MODULE_NAME = 'customer'

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)
    if (!store.hasModule(USER_CUSTOMER_STORE_MODULE_NAME)) store.registerModule(USER_CUSTOMER_STORE_MODULE_NAME, customerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
      if (store.hasModule(USER_CUSTOMER_STORE_MODULE_NAME)) store.unregisterModule(USER_CUSTOMER_STORE_MODULE_NAME)
    })

    const {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      refetchEvents,
      refetchResources,
      calendarOptions,
      calendarFunctions,
      currentDate,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    } = useCalendar()

    currentDate.value = moment().format('YYYY-MM-DD')

    return {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      refetchEvents,
      refetchResources,
      calendarOptions,
      calendarFunctions,
      currentDate,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
      avatarText,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";

.fc-timegrid-event .fc-event-main {
  padding: .5em;

  .fc-event-time {
    font-size: 1em;
  }

  .fc-event-title {
    font-weight: bold;
  }
}

.fc-timegrid-body {
  .fc-timegrid-event {
    border: 0;
  }
}
</style>
