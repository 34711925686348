import axios from '@axios'

export default {
  namespaced: true,
  state: {
    employeeOptions: [],
    selectedEmployees: [],
  },
  getters: {},
  mutations: {
    SET_SELECTED_EMPLOYEES(state, val) {
      state.selectedEmployees = val
    },
  },
  actions: {
    fetchEvents() {
      return new Promise((resolve, reject) => {
        axios
          .get('/appointments', {
            // params: {
            //   calendars: calendars.join(','),
            // },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchResources(ctx, { employees }) {
      const resources = ctx.state.employeeOptions.filter(obj => employees.includes(obj.id))
      return new Promise(resolve => {
        resolve(resources)
      })
    },
    fetchServices() {
      return new Promise((resolve, reject) => {
        axios
          .get('/services')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchEmployees(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/employees')
          .then(response => {
            const data = response.data['hydra:member']
            data.forEach(value => {
              ctx.state.employeeOptions.push({
                id: value.id,
                title: value.name,
                businessHours: {
                  startTime: '09:00',
                  endTime: '18:00',
                },
                color: value.calendarColor,
              })
              ctx.state.selectedEmployees.push(value.id)
            })
          })
          .catch(error => reject(error))
      })
    },
    addEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/appointment', { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/apps/calendar/events/${event.id}`, { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/calendar/events/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
