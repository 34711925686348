import { ref, computed, watch } from '@vue/composition-api'
import store from '@/store'
import Api from '@/libs/api'

export default function useCalendarEventHandler(props, clearForm, emit) {
  // ------------------------------------------------
  // eventLocal
  // ------------------------------------------------
  const eventLocal = ref(JSON.parse(JSON.stringify(props.event.value)))
  const resetEventLocal = () => {
    eventLocal.value = JSON.parse(JSON.stringify(props.event.value))
  }
  watch(props.event, () => {
    resetEventLocal()
  })

  // ------------------------------------------------
  // isEventHandlerSidebarActive
  // * Clear form if sidebar is closed
  // ------------------------------------------------
  watch(props.isEventHandlerSidebarActive, val => {
    // ? Don't reset event till transition is finished
    if (!val) {
      setTimeout(() => {
        clearForm.value()
      }, 350)
    }
  })
  // ------------------------------------------------
  // calendarOptions
  // ------------------------------------------------
  const employeeOptions = computed(() => store.state.calendar.employeeOptions)
  const serviceOptions = ref([])
  const customerOptions = ref([])

  Api.fetch('services').then(data => {
    serviceOptions.value = data['hydra:member']
  })
  Api.fetch('customers').then(data => {
    customerOptions.value = data['hydra:member']
  })

  const onSubmit = () => {
    const eventData = JSON.parse(JSON.stringify(eventLocal))

    // * If event has id => Edit Event
    // Emit event for add/update event
    if (props.event.value.id) emit('update-event', eventData.value)
    else emit('add-event', eventData.value)

    // Close sidebar
    emit('update:is-event-handler-sidebar-active', false)
  }

  return {
    eventLocal,
    resetEventLocal,
    employeeOptions,
    serviceOptions,
    customerOptions,

    // UI
    onSubmit,
  }
}
